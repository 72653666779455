
import { Vue, Options } from 'vue-class-component';

@Options({
  components: {
  },
  computed: {
  },
  watch: {
  },
  props: ['placement', 'colour', 'size', 'speed', 'delay', 'isBright', 'origin', 'fadeOut'],
  data() {
    return {
      // counter only uses this.initialCounter as the initial value;
      // it is disconnected from future prop updates.
    }
  }
})
export default class Pulse extends Vue {
  placement;
  colour;
  size;
  delay;
  speed = 'random';
  isBright;
  fadeOut;
  origin;

  colours = [
    "#199FB6",
    "#FD88FD",
    "#FED58C",
    "#0A3FA5",
    "#98D7FD",
    "#A64EC4",
    "#0B692F"
  ];

  randomColour;
  randomDelay;
  placementX;
  placementY;
  randomSize;
  randomSpeed;
  randomOriginX;
  randomOriginY;

  created() {
    //console.log(this.colour);

    //colour
    if(!this.colour) {
      this.randomColour = this.generateRandomColor();
      //console.log(this.randomColour);
    } else {
      this.randomColour = this.colour;
    }

    //placement

    if(!this.placement) {
      this.placementX = this.generatePlacement();
      this.placementY = this.generatePlacement();
    } else if(this.placement == 'center') {
      this.placementX = "50%";
      this.placementY = "50%";
    }

    if(!this.size) {
      this.randomSize = this.generateSize();
    } else {
      this.randomSize = this.size;
    }

    if(this.speed == 'random') {
      this.randomSpeed = this.generateSpeed();
    }

    if(!this.delay) {
      this.randomDelay = this.generateDelay();
    }

    if(!this.origin) {
      this.randomOriginX = this.generateOrigin();
      this.randomOriginY = this.generateOrigin();

    }
  }

  generateRandomColor() {
    return this.colours[Math.floor(Math.random() * this.colours.length)];
  
  }

  generatePlacement() {
    return Math.floor(Math.random() * 90) + '%';
  }

  generateOrigin() {
    return Math.floor(Math.random() * 100) + '%';
  }

  generateSize() {
    return Math.floor(Math.random() * 20) + 10 + 'em';
  }

  generateSpeed() {
    return (Math.floor(Math.random() * 5) + 1) + 's';
  }

  generateDelay() {
    return (Math.floor(Math.random() * 3)) + 's';
  }
  
}
