
import { Options, Vue } from 'vue-class-component';


@Options({
  components: {
    
  },
  computed: {
    BASE_URL () {
      return this.$store.getters.getBASEURL;
    }
  }
})
export default class CircleView extends Vue {
  BASE_URL;
  mask = true;
  timer = false;
  // swipe variable
  xPos = 50;
  yPos = 50;
  move = 10;
  // over variable
  clientX = 0;
  clientY = 0;
  // drag variable
  draggableElem;
  initialX = 0;
  initialY = 0; 
  moveElement = false;
  events = {
    mouse: {
      down: "mousedown",
      move: "mousemove",
      up: "mouseup",
    },
    touch: {
      down: "touchstart",
      move: "touchmove",
      up: "touchend",
    },
  };
  
  /*created() {
    console.log('created home',(this.$parent as any).$root.deviceClass);
  }*/

  mounted() {
    //console.log('mounted home');
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    this.draggableElem = document.getElementById("wrapper-mask");
    
    if((this.$parent as any).$root.deviceClass == 'mobile') {
      //Start touch start
      this.draggableElem.addEventListener('touchstart', this.handleTouch);
      //Move
      this.draggableElem.addEventListener('touchmove', this.handleMove);
      //mouse up / touch end
      this.draggableElem.addEventListener('touchend', this.stopMovement);
    }
    
  }

  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
    if((this.$parent as any).$root.deviceClass == 'mobile') {
      window.removeEventListener('touchstart', this.handleTouch);
      window.removeEventListener('touchstart', this.handleMove);
      window.removeEventListener('touchend', this.stopMovement);
    }
  }

  handleResize() {
    this.xPos = 50;
    this.yPos = 50;
    if((this.$parent as any).$root.deviceClass == 'desktop') {
      this.clientX = -15;
      this.clientY = -15;
    }
  }

  handleTouch(e) {
    //initial x and y points
    this.initialX = e.touches[0].clientX;
    this.initialY = e.touches[0].clientY;

    //Start movement
    this.moveElement = true;

    e.preventDefault();
  }

  handleMove(e) {
    //console.log("move");
    let w = window.innerWidth - 150,
      h = window.innerHeight - 150,
      newX = e.touches[0].clientX,
      newY = e.touches[0].clientY,
      moveX,
      moveY;
    //if movement == true then set top and left to new X andY while removing any offset
    //console.log(newX,newY);
    //console.log(w+'/'+this.initialX,h+'/'+this.initialY);
    // detect drag direction
    if(newY > this.initialY) {
      moveY = 'down';
    } else {
      moveY = 'up';
    }

    if(newX > this.initialX) {
      moveX = 'right';
    } else {
      moveX = 'left';
    }

    //console.log(moveX,moveY);

    /*if((moveY == 'down' && this.initialY <= h) || 
      (moveY == 'up' && this.initialY >= h) ||
      (moveX == 'right' && this.initialX >= w) || 
      (moveX == 'left' && this.initialX <= w)) {
      this.moveElement = false;
    }*/
    // only working if mask on
    if (this.moveElement && this.mask) {
      if(!this.timer)
        this.openMask();

      let newX = e.touches[0].clientX,
        newY = e.touches[0].clientY;

      this.clientY = this.draggableElem.offsetTop - (this.initialY - newY);
      this.clientX = this.draggableElem.offsetLeft - (this.initialX - newX);

      this.initialX = newX;
      this.initialY = newY;

      this.$forceUpdate();
      //console.log(this.initialX,this.initialY);
    } /*else {
      console.log('dont move');
    }*/
    e.preventDefault();
  }

  stopMovement(e) {
    this.moveElement = false;
    e.preventDefault();
  }

  swipeHandler(mouseEvent) {
    // only working if mask on
    if((this.$parent as any).$root.deviceClass == 'mobile' && this.mask) {
      if(!this.timer)
        this.openMask();

      if(mouseEvent == 'top' && this.yPos < 100) {
        this.yPos = this.yPos + this.move;
      } else if(mouseEvent == 'bottom' && this.yPos > 0) {
        this.yPos = this.yPos - this.move;
      } else if(mouseEvent == 'left' && this.xPos < 100) {
        this.xPos = this.xPos + this.move;
      } else if(mouseEvent == 'right' && this.xPos > 0) {
        this.xPos = this.xPos - this.move;
      }
      this.$forceUpdate();
    }
  }

  moveMouse($event) {
    //console.log("move");
    // only working if mask on
    if((this.$parent as any).$root.deviceClass == 'desktop' && this.mask) {
      if(!this.timer)
        this.openMask();

      this.clientX = $event.clientX - window.innerWidth / 2;
      this.clientY = $event.clientY - window.innerHeight / 2;
      this.$forceUpdate();
    }
  }

  openMask() {
    this.timer = true;
    setTimeout(() => {
      //this.mask = false;
    }, 5000);
  }
}
