
import { Vue, Options } from 'vue-class-component';

//import { Component, Vue, Prop/*, Watch*/ } from 'vue-property-decorator'

@Options({
  components: {
  },
  computed: {
    muted() {
      return this.$store.state.muted;
    }
  },
  watch: {
    src(newSrc, oldSrc) {
        //console.log(newSrc);
        
    }
  },
  props: ['src', 'autoplay', 'loop']
})
export default class Audio extends Vue {

    src; 
    autoplay; 
    loop;

    public player;

    //public muted = false;
    public fadeInterval;
    public fadeOuterval;
    public progressInterval;
    public currentProgress = 0;
    public isPlaying = false;
    
    created() {
        //console.log(this.src);
    }
 
    //after view is loaded
    mounted() {
        //console.log(this.$el.nextElementSibling);
        //let player = document.getElementById('audioPlayer');
        this.player = this.$el;
        //this.player = player;
        //console.log(this.player);
    }

    destroyed() {
        if(this.progressInterval) {
            clearInterval(this.progressInterval);
        }
    }

    play() {
        //console.log('play',this.player);
        // detect global mute
        let muted = this.$store.state.muted,
            video = this.$store.state.videoP;
        
        if(this.player.readyState > 1) {
            // volume off if muted or video player opened
            if(muted || video)
                this.player.volume = 0;
            else
                this.player.volume = 1;

            this.player.play();
            
        } else {
            // detect iOS or iPad devices
            if(((this.$root as any).osClass == 'iOS' || (this.$root as any).osClass == 'iPadOS') && !muted && !video) {
                this.player.play();
            } else {
                this.player.oncanplay = () => {
                    this.player.play();
                }
            }
        }
        // detect if audio ended to play the ambient
        //console.log(this.$parent);
        this.startProgressTimer();
    }

    mute(mute) {
        //console.log('mute');
        // clear fade interval 
        clearInterval(this.fadeInterval);
        clearInterval(this.fadeOuterval);
        //console.log("mute: " + mute,this.player);
        //this.muted = mute;
        this.$store.commit("SET_MUTED", mute);
        this.player.muted = mute;
        // force volume on
        this.player.volume = 1;
    }

    pause() {
        //console.log('pause');
        this.player.pause();
    }

    onReady() {
        //console.log('onReady');
        if(this.autoplay)
            this.play()
    }

    onError() {
        console.log("error has occured on audio player");
    }

    /*getAudioSrc() {
        return require(`~/assets/audio/${this.src}`);
    }*/

    fadeAudioIn() {
        let muted = this.$store.state.muted;
        if(this.player.paused && !muted)
            this.player.play();
        let vol = 100;
        this.fadeInterval = setInterval(() => { 
            if(vol > 0) {
                vol--;
                this.player.volume = 0.+(vol/100);
            } else {
                this.player.volume = 0;
                clearInterval(this.fadeInterval);
            }
        }, 10);
    }

    fadeAudioOut() {
        let muted = this.$store.state.muted;
        if(this.player.paused && !muted)
            this.player.play();
        let vol = 0;
        this.fadeOuterval = setInterval(() => { 
            if(vol < 100) {
                vol++;
                this.player.volume = 0.+(vol/100);
            } else {
                this.player.volume = 1;
                clearInterval(this.fadeOuterval);
            }
        }, 10);
    }

    startProgressTimer() {
        clearInterval(this.progressInterval);
        this.currentProgress = 0;
        //console.log(this.player);
        if(!this.player.loop) {
            this.progressInterval = setInterval(() => {
                this.currentProgress = this.player.currentTime * 100 / this.player.duration;
                // force clear if audio not playing
                if(this.currentProgress == 0)
                    clearInterval(this.progressInterval);
            }, 1000);

            this.player.onended = () => {
                this.currentProgress = 100;
                this.isPlaying = false;
                clearInterval(this.progressInterval);
                if((this.$parent as any).audioChanged){

                    (this.$parent as any).audioChanged((this.$parent as any).currentAudioBG);
                    (this.$parent as any).loopChanged(true);
                    this.$store.commit("setAudioCircle", null);
                }
            }
        }
    }

}
