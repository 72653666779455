
import { Vue, Options } from 'vue-class-component';

import profiles from '@/assets/json/profiles.json';
import Pulse from '@/components/pulse/pulse.vue';
import Audio from '@/components/audio/audio.vue';

@Options({
  components: {
    Pulse,
    Audio
  },
  computed: {
    modalOpen() {
      return this.$store.state.modalOpen;
    }
  },
  watch: {
    modalOpen(newValue) {
      this.$store.commit("setModalOpen", newValue);
    }
  },
  props: []
})
export default class NavBar extends Vue {
  private search = '';
  private errors = false;
  private s = null;
  private iptFoc = false;
  private baseURL;
  private hideBanner = false;
  private scrollPos = 0;
  private open = false;
  profiles: any[] = profiles;
  private section = null;
  private effectAudio = ''


  private modalContent = { 'id': '371233663',
              'type': 'vimeo',
              'controls': true};
  
  created() {  
      this.baseURL = process.env.baseURL;  
      //console.log('profiles',this.profiles);
  }

  mounted() {
    this.effectAudio = 'click_up.mp3';
  }

  toggleNav(e) {
    this.open = !this.open;
    this.section = null;
    (this.$refs.boop as any).play()

    //let open = this.$store.state.menuOpen;
    //this.$store.commit("SET_MENUOPEN", !open);
    e.preventDefault();
    this.$emit('toggleNav');

    

    if(!this.open) {
      this.enableScroll()
    } else {
      this.disableScroll();
    }
  }

  closeNav() {
    //this.$store.commit("SET_MENUOPEN", false);
    this.open = false;
    this.enableScroll();
  }

  openNav()  {
    this.open = true;
    this.disableScroll();
    //this.$store.commit("SET_MENUOPEN", true);
  }

  goTo(index) {
    this.closeNav();
    this.$emit('goTo', index);
  }

  openView(id) {
    this.section = id;
  }

  goHome() {
    this.$router.push('/');
  }


  disableScroll() {
        let body = document.getElementsByTagName("BODY")[0]; 

        body.classList.add("no-scroll");
    }

    enableScroll() {
        let body = document.getElementsByTagName("BODY")[0]; 

        body.classList.remove("no-scroll");
    }

  // change language on current page
  changeLanguage(language,e) {
    let url = window.location.pathname.slice(14);
    this.$router.push('/'+language+url);
    e.preventDefault();
  }

  checkForm(e) {
    if (this.s) {
      return true;
    }

    this.errors = false;

    if (!this.s) {
      this.errors = true as any;
    }

    e.preventDefault();
  }
}
