import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx, _cache) {
  return (_ctx.randomColour)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["pulse", {'bright': _ctx.isBright, 'fade-out': _ctx.fadeOut}]),
        style: _normalizeStyle({'background': 'radial-gradient(circle at center,'+(_ctx.randomColour)+', transparent 65%)', 'left': _ctx.placementX, 'top': _ctx.placementY, 'width': _ctx.randomSize, 'height': _ctx.randomSize, 'animation-duration': _ctx.randomSpeed, 'animation-delay': _ctx.randomDelay, 'transform-origin': _ctx.randomOriginX + ' ' + _ctx.randomOriginY})
      }, null, 6))
    : _createCommentVNode("", true)
}