import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src", "autoplay", "loop", "controls"]

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("audio", {
    id: "audioPlayer",
    src: require('@/assets/audio/'+_ctx.src),
    autoplay: _ctx.autoplay,
    loop: _ctx.loop,
    preload: "auto",
    onCanplay: _cache[0] || (_cache[0] = (...args) => (_ctx.onReady && _ctx.onReady(...args))),
    onError: _cache[1] || (_cache[1] = (...args) => (_ctx.onError && _ctx.onError(...args))),
    controls: _ctx.controls
  }, null, 40, _hoisted_1))
}