
//import * as Vue from "vue";
import { inject, ref } from 'vue'
import { Vue, Options }  from "vue-class-component";

import Circle from "@/components/circle/circle.vue";
import VideoCircle from "@/components/video-circle/video-circle.vue";
import CanvasCircle from "@/components/canvas-circle/canvas-circle.vue";
import Audio from  "@/components/audio/audio.vue";
@Options({
  components: {
    Circle,
    VideoCircle,
    CanvasCircle,
    Audio
  },
  computed: {
    wallText() {
      return this.$store.state.wallText;
    },
    wallAnim() {
      return this.$store.state.wallAnim;
    },
    isAudioCircle() {
      return this.$store.state.isAudioCircle;
    }
  },
  watch: {
    wallText(newValue) {
      this.$store.commit("setWallText", newValue);
    },
    wallAnim(newValue) {
      this.$store.commit("setWallAnim", newValue);
    },
    isAudioCircle(newValue) {
      this.$store.commit("setAudioCircle", newValue);
    }
  },
  props: ['profile', 'next', 'id', 'active']
})

export default class Profile extends Vue {

  profile;
  next;
  id;
  initialized = false;
  scrolled = false;
  smoothScroll;
  isAudioActive = false;
  scrollTop = 0;
  isAudioCircle;
  changedAudio = false;
  defaultAudio = 'ambiance-globale.mp3';
  minHeight = 0;
  name;

  /*setup() {
    console.log("setup");
    
  }*/

  created() {
    const myEl = ref(null)
    this.smoothScroll = inject('smoothScroll')
    //console.log(this.next);
    // detect mobile for setting min height
    //if(window.innerWidth > 750)
      //this.minHeight = (this.profile.content.length*400)/2;
    //else
      //this.minHeight = (this.profile.content.length*200)/2 - 100;
  }

  mounted() {
    window.scrollTo(0,0);
    document.body.addEventListener('scroll', this.handleScroll);
    //console.log(this.next);
  }

  moveSlider(dir) {

    this.smoothScroll({
      scrollTo: document.getElementById("app"),
      updateHistory: true,
      duration: 200
    });

    setTimeout(() => {
      this.initialized = false;
      (this.$refs.changeAudio as any).play();

      this.$emit('moveSlider', dir);
      this.$forceUpdate();
    }, 200);
  }

  goTo(index) {
    //(this.$refs.effect as any).play();

    this.smoothScroll({
      scrollTo: document.getElementById("app"),
      updateHistory: true,
      duration: 200
    });

    setTimeout(() => {
      this.initialized = false;
      this.$emit('goTo', 0);
      this.$forceUpdate();
    }, 200);
  }

  isActive() {
    return (this.id == this.$route.params.id);
  }

  scrollDown() {
    //console.log(this.profile.content);
    if(this.profile.content.length > 0){
      //console.log("going");
      this.smoothScroll({
        scrollTo: this.$el.querySelector('#content'),
        updateHistory: true
      });
    }
  }

  handleScroll($event) {
    this.scrollTop = $event.target.scrollTop;
    if(this.isActive() && this.initialized) {

      if($event.target.scrollTop == 0) {
        this.isAudioActive = false;
        //this.triggerScroll(null);
        this.scrolled = false;
        this.$emit('audioChanged', this.defaultAudio);
        this.$emit('loopChanged', true);
        this.changedAudio = false;
      } else if(!this.isAudioActive) {
        this.triggerScroll(this.profile.bgAudio);
      }
    }
    
  }

  triggerScroll(audio) {
    //console.log('triggerscroll',audio);
    if(audio) {
      this.scrolled = true;
    } else {
      this.scrolled = false;
    }
    // changed background audio only if we doesnt listen an audio file
    if(!this.isAudioCircle && !this.changedAudio) {
      this.$emit('audioChanged', audio);
      this.$emit('loopChanged', true);
      this.changedAudio = true;
    }
    this.$forceUpdate();

  }

  init() {
    //console.log("init");
    this.initialized = true;
    this.$emit('init', true);
  }

  triggerActiveAudio(audio=null,id=null) {
    //console.log(this.$parent.$refs.main);
    if((!this.isAudioCircle && audio && id) || (this.isAudioCircle != null && this.isAudioCircle != id && audio)) { // control for video circle
      this.$store.commit("setAudioCircle", id);
      //this.isAudioCircle = id;
      this.isAudioActive = false;
      this.$emit('loopChanged', false);
      this.$emit('audioChanged', audio);
      //console.log('1');
      //console.log('audio file',this.$parent.$refs.main);
    } else if(!this.isAudioActive && !audio && !id) { // control for main audio
      this.isAudioActive = true;
      this.$store.commit("setAudioCircle", null);
      //this.isAudioCircle = null;
      this.$emit('audioChanged', this.profile.audio);
      this.$emit('loopChanged', false);
      //console.log('2');
    } else { 
      this.isAudioActive = false;
      this.$store.commit("setAudioCircle", null);
      //this.isAudioCircle = null;
      this.$emit('audioChanged', this.profile.bgAudio);
      this.$emit('loopChanged', true);
      //console.log('3');
    }
  }

  openLightbox(image) {
    this.$emit('openLightbox', image);
  }

}
