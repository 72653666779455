
import { Vue, Options } from 'vue-class-component';

@Options({
  components: {
  },
  computed: {
  },
  watch: {
  },
  props: ['src'],
  data() {
    return {
      // counter only uses this.initialCounter as the initial value;
      // it is disconnected from future prop updates.
    }
  }
})
export default class Lightbox extends Vue {
  src;
  initialized = false;

  /*created() {
    console.log('lightbox');
  }*/

  mounted() {
    setTimeout(() => {
        this.initialized = true;
    }, 10);
  }

  closeLightbox() {
    this.initialized = false;
    setTimeout(() => {
        this.$emit('closeLightbox');
    }, 200);
  }
  
}
