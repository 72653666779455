
import { Vue, Options } from 'vue-class-component';

@Options({
  components: {
  },
  computed: {
  },
  watch: {
  },
  props: ['placement', 'colour', 'size', 'speed', 'content', 'scrollTop','index','total'],
  data() {
    return {
      // counter only uses this.initialCounter as the initial value;
      // it is disconnected from future prop updates.
    }
  }
})
export default class VideoCircle extends Vue {
  placement;
  colour;
  size;
  speed = 'random';
  content;
  scrollTop;
  index;
  total;

  colours = [
    "#199FB6",
    "#FD88FD",
    "#FED58C",
    "#0A3FA5",
    "#98D7FD",
    "#A64EC4",
    "#0B692F"
  ];

  randomColour;
  randomDelay;
  placementX;
  placementY;
  placementClass;
  placements = ['left', 'middle', 'right'];
  placementTop;
  randomSize;
  randomSpeed;
  randomOriginX;
  randomOriginY;
  parallaxSpeed;
  space;
  progress = '308%';
  progInterval;

  created() {
    this.space = 75 / this.total;

    /*if(this.content.type == "text") {
      console.log(this.content);
    }*/

    //colour
    if(!this.colour) {
      this.randomColour = this.generateRandomColor();
    } else {
      this.randomColour = this.colour;
    }


    //placement
    if(!this.placement) {
      //this.placementX = this.generatePlacement() + '%';
      //this.placementY = this.generatePlacement() + '%';
      this.placementClass = this.placements[Math.floor(Math.random()*this.placements.length)];
      //console.log(this.placementClass);
      if(window.innerWidth > 750) {
        this.placementX = ((this.index+1) % 2 == 0 ? Math.floor(Math.random() * 30) + 1 :  Math.floor(Math.random() * (80 - 50 + 1)) + 50);
        this.placementTop = Math.floor(this.space * (this.index+1)) + '%';
      } else {
        this.placementX = ((this.index+1) % 2 == 0 ? Math.floor(Math.random() * 10) + 1 :  Math.floor(Math.random() * (30 - 20 + 1)) + 20);
        this.placementTop = Math.floor(this.space * (this.index+1)) - 10 + '%';
      }
      
    } else if(this.placement == 'center') {
      this.placementX = "50";
      this.placementY = "50";
    }

    if(!this.size) {
      this.randomSize = this.generateSize();
    } else {
      this.randomSize = this.size;
    }

    if(this.speed == 'random') {
      this.randomSpeed = this.generateSpeed();
    }

    this.parallaxSpeed = this.generateParallaxSpeed();
    // reset position on resize
    //window.addEventListener("resize", this.resetCircle);
  }

  mounted() {
    //console.log(this.$el.previousElementSibling)
    if(this.$el.previousElementSibling && 
      this.$el.previousElementSibling.classList &&
    this.$el.previousElementSibling.classList[0] == 'video-circle') {
      let previousClass = this.$el.previousElementSibling.classList[2];
      //console.log(previousClass);

      do {
        this.placementClass = this.placements[Math.floor(Math.random()*this.placements.length)];
      } while(this.placementClass !== previousClass)

    } else {
      this.placementClass = this.placements[Math.floor(Math.random()*this.placements.length)];
    }
  }

  resetCircle() {
    //console.log('reset circle')
  }

  generateRandomColor() {
    return this.colours[Math.floor(Math.random() * this.colours.length)];
  
  }

  generatePlacement() {
    return Math.floor(Math.random() * 60) + 10;
  }

  generateOrigin() {
    return Math.floor(Math.random() * 100) + '%';
  }

  generateSize() {
    let size = (window.innerWidth > 750 ? 40 : 15);
    return Math.floor(Math.random() * 20) + size + 'em';
  }

  generateSpeed() {
    return (Math.floor(Math.random() * 5) + 1) + 's';
  }

  generateDelay() {
    return (Math.floor(Math.random() * 3)) + 's';
  }

  generateParallaxSpeed() {
    return parseFloat(((Math.random() * 0.3) - 0.15 as any).toFixed(2));
  }

  getParallaxPosition() {
    return (this.scrollTop * this.parallaxSpeed) + 'px';
  }

  openLightbox(content) {
    if(content.image)
      this.$emit('openLightbox', content.image);

  }

  triggerActiveAudio(audio, id) {

    (this.$parent as any).triggerActiveAudio(audio, id);

    setTimeout(() => {
      this.progInterval = setInterval(() => {
        this.getProgress(this.$parent.$parent.$refs.main, this.index);
      });
    }, 1000);
  }

  getProgress(audObj,index) {
      if(typeof(document) != 'undefined') {
          let circle = document.getElementById("polyProgress"+(index+1));
          //console.log(circle);
          if(circle !== null && audObj) {
              let radius = (circle as any).r.baseVal.value;
              let circumference = radius * 2 * Math.PI;
              //console.log('progress',audObj.currentProgress);
              let offset = 308 - (audObj.currentProgress * 308 / 100) + '%';

              //let offset = circumference - audObj.currentProgress / 100 * circumference;
              //console.log(offset);
              
              this.progress = offset;
              this.$forceUpdate();
          } else {
              //console.log("failing")
              this.progress = "308%";
              clearInterval(this.progInterval);
          }
      } else {
          this.progress = "308%";
      }
  }
  
}
