
import { inject, ref } from 'vue'

import { Options, Vue } from 'vue-class-component';

import Profile from "@/components/profile/profile.vue";
import Audio from '@/components/audio/audio.vue';
import NavBar from '@/components/nav-bar/nav-bar.vue';
import Lightbox from '@/components/lightbox/lightbox.vue';
import { event } from 'vue-gtag';
import profiles from '@/assets/json/profiles.json';


@Options({
  components: {
    Profile,
    Audio,
    NavBar,
    Lightbox
  },
  computed: {
    BASE_URL () {
      return this.$store.getters.getBASEURL;
    }
  }
})
export default class ProfileView extends Vue {
  BASE_URL;
  id: number = null;
  profiles: any[] = profiles;
  defaultAudio = 'ambiance-globale.mp3';
  audioFile = '';
  ambiances = ['ambiance1.mp3', 'ambiance2.mp3', 'ambiance3.mp3', 'ambiance4.mp3', 'ambiance5.mp3', 'ambiance6.mp3']
  effectAudio = 'click_left.mp3';
  smoothScroll;
  lightbox = null;
  intTxt;
  intAnim;
  loop = true;
  currentAudioBG;
  init = false;
  
  created() {
    this.id = parseInt(this.$route.params.id as any);
    // analytics
    event(null, { 
      event: null, // Event type [default = 'interaction'] (Optional)
      event_category: 'Profile',
      action: 'click',
      event_label: 'Profile Views: '+this.id,
      value: 5000,
      noninteraction: false, // Optional
    });

    const myEl = ref(null)
    this.smoothScroll = inject('smoothScroll');

    //this.audioFile = this.ambiances[Math.floor(Math.random()*this.ambiances.length)];
    this.audioFile = this.profiles[this.id].ambientAudio;

    //console.log(this.audioFile);
    this.startAnim();
    this.currentAudioBG = this.profiles[this.id].bgAudio;
  }

  moveSlider(dir) {
    //console.log(this.$refs);
    /*console.log('dir',dir);
    console.log('this.id',this.id);*/
    if(this.profiles[this.id+dir]) {

      
      setTimeout(() => {
        // show wall text
        clearTimeout(this.intTxt);
        clearTimeout(this.intAnim);
        this.$store.commit("setWallText", true);
        this.$store.commit("setWallAnim", false);
        this.currentAudioBG = this.profiles[this.id+dir].bgAudio;
        this.init = false;
        // hide wall text
        this.startAnim();
      }, 500);

      this.smoothScroll({
        scrollTo: document.body,
        updateHistory: true
      });

      setTimeout(() => {
        this.id = this.id+dir;
        //this.audioFile = this.ambiances[Math.floor(Math.random()*this.ambiances.length)];
        this.audioFile = this.profiles[this.id].ambientAudio;

        //let queries = JSON.parse(JSON.stringify(this.$route));
        this.$router.replace({'path': '/profile/'+this.id});
        this.$forceUpdate();
      }, 1000);
    }
  }

  goTo(index) {
    if(this.profiles[index]) {

      this.init = false;

      this.smoothScroll({
        scrollTo: document.body,
        updateHistory: true
      });
      document.body.scrollTop = 0;


      setTimeout(() => {
        this.id = index;
        //this.audioFile = this.ambiances[Math.floor(Math.random()*this.ambiances.length)];
        this.audioFile = this.profiles[this.id].ambientAudio;

        //let queries = JSON.parse(JSON.stringify(this.$route));
        this.$router.replace({'path': '/profile/'+this.id});
        this.$forceUpdate();
      }, 1000);

      

      
    }
  }

  audioChanged(audio=null) {
    //console.log('audio changed',audio);
    if(audio) {
      this.audioFile = audio;
    } else {
      this.audioFile = this.defaultAudio;
    }

    this.$forceUpdate();

  }

  loopChanged(loop: any) {
    this.loop = loop;

    this.$forceUpdate();

  }

  openLightbox(image) {
    //console.log(image);
    this.lightbox = image;
  }

  closeLightbox() {
    this.lightbox = null;
  }

  startAnim() {
    this.intTxt = setTimeout(() => {
      this.animWall();
        this.intAnim = setTimeout(() => {
        this.hideWall();
      }, 4000);
    }, 1500);
  }

  hideWall() {
    this.$store.commit("setWallText", false);
  }

  animWall() {
    this.$store.commit("setWallAnim", true);
  }

  fireAudio() {
    //console.log("firing");
    //(this.$refs.boop as any).play();
  }

}
