
import { Options, Vue } from 'vue-class-component';
import Pulse from '@/components/pulse/pulse.vue';
import Audio from '@/components/audio/audio.vue';
import { event } from 'vue-gtag';

@Options({
  components: {
    Pulse,
    Audio
  },
  computed: {
    BASE_URL () {
      return this.$store.getters.getBASEURL;
    }
  }
})
export default class HomeView extends Vue {
  BASE_URL;
  audioFile = 'intro.mp3';
  clicked = false;
  show = false;
  
  created() {
    //console.log('created home',(this.$parent as any).$root.deviceClass);
    // analytics
    event(null, { 
      event: null, // Event type [default = 'interaction'] (Optional)
      event_category: 'Home',
      action: 'click',
      event_label: 'Home Views',
      value: 5000,
      noninteraction: false, // Optional
    });

    setTimeout(() => {
      this.show = true;
    }, 5000);
  }

  /*mounted() {
    console.log('mounted home');    
  }

  beforeUnmount() {
    console.log('unmounted home');  
  }*/

  next() {
    this.clicked = true;
    //(this.$refs.main as any).mute();
    setTimeout(() => {
      (this.$refs.aud as any).play();
      setTimeout(() => {
        this.$router.push('/profile/0');
      },2000);
    }, 100);
    
  }

}
