import { createApp } from 'vue'
//import { createGtm } from '@gtm-support/vue-gtm'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueGtag from "vue-gtag";
import Vue3TouchEvents from "vue3-touch-events"
import { createMetaManager } from 'vue-meta'
import VueSmoothScroll from 'vue3-smooth-scroll';

const app = createApp(App)
.use(store)
.use(router)
/*.use(
  createGtm({
    id: 'GTM-M2CW6D5X' // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
  }),
)*/
.use(createMetaManager()) // add this line
.use(VueAxios, axios)
.use(VueSmoothScroll)
.use(VueGtag, {
  config: { 
    id: "G-G4MY3LY312",
    params: {
      anonymize_ip: true
    }
  }
}, router)
.use(Vue3TouchEvents)
app.mount('#app')
