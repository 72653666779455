
import { Vue, Options } from 'vue-class-component';

@Options({
  components: {
  },
  computed: {
  },
  watch: {
  },
  props: ['colour', 'size']
})
export default class CanvasCircle extends Vue {
  
  oldMousePoint = { x: 0, y: 0};
  
  colour = 'white';
  margin = 50;
  numPoints = 32;
  size = 0;
  data;
  img;
  points = [];
  ctx;

  _position;

  colours = [
    "#199FB6",
    "#FD88FD",
    "#FED58C",
    "#0A3FA5",
    "#98D7FD",
    "#A64EC4",
    "#0B692F"
  ];

  randomColour;

  


  created() {
    //colour
    if(!this.colour) {
      this.randomColour = this.generateRandomColour();
      //console.log(this.randomColour);
    } else {
      this.randomColour = this.colour;
    }

    if(this.size == 0) {
        this.size = 500;
    }

    this.createTexture();

    for(let i = 0; i < this.numPoints; i++) {
        let point = new Point(this.divisional * i, this);
        this.points.push(point);
    }

    
  } 

  mounted() {
    //console.log(this.$refs);

    this.ctx = (this.$refs.canvas as any).getContext("2d");
    //console.log(this.ctx);

    window.addEventListener("resize", this.resizeCanvas);

    this.renderCircle();
  }

  resizeCanvas() {

        //console.log("resize");
        //if(this.$refs.canvas) {
            //this.size = (this.$refs.canvas as any).clientWidth;
            this.createTexture();
        //}
    }

  

  createTexture() {
    const data = Uint32Array.from(
      { length: this.size * this.size },
      () => (Math.random() > 0.8 ? 0xff000000 : 0)
    );
    this.img = new ImageData(
      new Uint8ClampedArray(data.buffer),
      this.size,
      this.size
    );
  }

  drawCircle(hasToBeStroke) {
    let ctx = this.ctx;
    let position = this.position;
    let pointsArray = this.points;
    let radius = this.radius;
    let points = this.numPoints;
    let divisional = this.divisional;
    let center = this.center;

    ctx.beginPath();

    let lastPoint = pointsArray[points - 2];

    let actualPoint;
    let nextPoint;
    var xc;
    var yc;

    actualPoint = pointsArray[points - 2];
    nextPoint = pointsArray[points - 1];

    ctx.moveTo(center.x, center.y);
    // ctx.moveTo(actualPoint.position.x, actualPoint.position.y);
    this.drawSegment(
      ctx,
      points,
      pointsArray,
      actualPoint,
      nextPoint,
      lastPoint,
      "green",
      15
    );

    actualPoint = pointsArray[points - 1];
    nextPoint = pointsArray[0];
    this.drawSegment(
      ctx,
      points,
      pointsArray,
      actualPoint,
      nextPoint,
      lastPoint,
      "green",
      12
    );

    for (let i = 0; i < points; i++) {
      let actualPoint = pointsArray[i];
      let nextPoint = pointsArray[i + 1] || pointsArray[0];
      this.drawSegment(
        ctx,
        points,
        pointsArray,
        actualPoint,
        nextPoint,
        lastPoint,
        "blue",
        2
      );
      lastPoint = actualPoint;
    }

    actualPoint = pointsArray[points - 1];
    nextPoint = pointsArray[0];
    this.drawSegment(
      ctx,
      points,
      pointsArray,
      actualPoint,
      { position: { x: center.x, y: center.y } },
      lastPoint,
      "red",
      6
    );

    if (hasToBeStroke) {
      ctx.lineWidth = 3;
      ctx.strokeStyle = this.colour;
      ctx.stroke();
    } else {
      ctx.fillStyle = "rgba(0,0,0,1)";
      ctx.fill();
    }
    ctx.closePath();
  }

  renderCircle() {
    if(this.$refs.canvas) {
        let ctx = this.ctx;
        let pointsArray = this.points;
        let points = this.numPoints;

        for (let i = 0; i < points; i++) {
        pointsArray[i].solveWith(
            pointsArray[i - 1] || pointsArray[points - 1],
            pointsArray[i + 1] || pointsArray[0]
        );
        }

        ctx.clearRect(0, 0, (this.$refs.canvas as any).width, (this.$refs.canvas as any).height);

        this.drawCircle(true);
        ctx.save();
        ctx.globalCompositeOperation = "destination-out";
        this.drawCircle(false);
        ctx.restore();

        ctx.translate(0, 0);

        //     let test = ctx.getImageData(0, 0, this.canvas.width, this.canvas.height);
        //     // ctx.putImageData(test, 10, 0);
        //     // ctx.translate(0, 10);
        //     ctx.putImageData(test, 0, 0);
        //     ctx.translate(0, 0);

        this.points.map((point) => {
        if (Math.random() > 0.99) {
            point.acceleration = -0.05 + Math.random() * 0.1;
        }
        });

        //console.log("rendering");
    }


    requestAnimationFrame(this.renderCircle.bind(this));
  }

  drawSegment(
    ctx,
    points,
    pointsArray,
    actualPoint,
    nextPoint,
    lastPoint,
    color,
    size
  ) {
    var xc = (actualPoint.position.x + nextPoint.position.x) / 2;
    var yc = (actualPoint.position.y + nextPoint.position.y) / 2;

    // for debugging
     ctx.fillStyle = color;
     ctx.fillRect(actualPoint.position.x-size / 2, actualPoint.position.y-size / 2, size, size);
    ctx.quadraticCurveTo(
      actualPoint.position.x,
      actualPoint.position.y,
      xc,
      yc
    );

    lastPoint = actualPoint;
  }

  

  generateRandomColour() {
    return this.colours[Math.floor(Math.random() * this.colours.length)];
  }

  

  get divisional() {
    return (Math.PI * 2) / this.numPoints;
  }

  set position(value) {
    if (typeof value == "object" && value.x && value.y) {
      this._position = value;
    }
  }

  get position() {
    return this._position || { x: 0.5, y: 0.5 };
  }

  get radius() {
    if ((this.$refs.canvas as any).clientWidth < (this.$refs.canvas as any).clientHeight) {
      return ((this.$refs.canvas as any).clientWidth / 2 - this.margin) * this.size;
    } else {
      return ((this.$refs.canvas as any).clientHeight / 2 - this.margin) * this.size;
    }
  }
  get center() {
    return {
      x: (this.$refs.canvas as any).width * this.position.x,
      y: (this.$refs.canvas as any).height * this.position.y
    };
  }
}

class Point {
  parent;
  azimuth;
  _components;
  _acceleration;
  _speed;
  _radialEffect;
  _elasticity;
  _friction;
  constructor(azimuth, parent) {
    this.parent = parent;
    this.azimuth = Math.PI - azimuth;
    this._components = {
      x: Math.cos(this.azimuth),
      y: Math.sin(this.azimuth)
    };
    this.acceleration = -0.3 + Math.random() * 0.6;
  }

  solveWith(leftPoint, rightPoint) {
    this.acceleration =
      (-0.3 * this.radialEffect +
        (leftPoint.radialEffect - this.radialEffect) +
        (rightPoint.radialEffect - this.radialEffect)) *
        this.elasticity -
      this.speed * this.friction;
  }

  set acceleration(value) {
    if (typeof value == "number") {
      this._acceleration = value;
      this.speed += this._acceleration * 2;
    }
  }
  get acceleration() {
    return this._acceleration || 0;
  }

  set speed(value) {
    if (typeof value == "number") {
      this._speed = value;
      this.radialEffect += this._speed * 5;
    }
  }
  get speed() {
    return this._speed || 0;
  }

  set radialEffect(value) {
    if (typeof value == "number") {
      this._radialEffect = value;
    }
  }
  get radialEffect() {
    return this._radialEffect || 0;
  }

  get position() {
    return {
      x:
        this.parent.center.x +
        this.components.x * (this.parent.radius + this.radialEffect),
      y:
        this.parent.center.y +
        this.components.y * (this.parent.radius + this.radialEffect)
    };
  }

  get components() {
    return this._components;
  }

  set elasticity(value) {
    if (typeof value === "number") {
      this._elasticity = value;
    }
  }
  get elasticity() {
    return this._elasticity || 0.001;
  }
  set friction(value) {
    if (typeof value === "number") {
      this._friction = value;
    }
  }
  get friction() {
    return this._friction || 0.0085;
  }
}
