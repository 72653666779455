
import { Vue, Options } from 'vue-class-component';
import Pulse from "@/components/pulse/pulse.vue";


@Options({
  components: {
    Pulse
  },
  computed: {
    BASE_URL () {
      return this.$store.getters.getBASEURL;
    }
  },
  watch: {
  },
  props: ['color','background', 'name']
})
export default class Circle extends Vue {
  color;
  background;
  BASE_URL;
  mask = true;
  timer = false;
  // swipe variable
  xPos = 50;
  yPos = 50;
  move = 10;
  // over variable
  clientX = "8%";
  clientY = 0;
  // drag variable
  draggableElem;
  initialX = 0;
  initialY = 0; 
  moveElement = false;
  events = {
    mouse: {
      down: "mousedown",
      move: "mousemove",
      up: "mouseup",
    },
    touch: {
      down: "touchstart",
      move: "touchmove",
      up: "touchend",
    },
  };
  touchStart;
  touchMove;
  touchEnd;

  started = false;

  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    this.draggableElem = document.getElementById("wrapper-mask");
    //console.log((this.$parent as any).$root.deviceClass);
    
    try {
      //Start touch start
      this.draggableElem.addEventListener('touchstart', this.handleTouch);
      //Move
      this.draggableElem.addEventListener('touchmove', this.handleMove);
      //mouse up / touch end
      this.draggableElem.addEventListener('touchend', this.stopMovement);
    } catch(e) {
      console.log("no touch events")
    }
    
  }

  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
    try {
      window.removeEventListener('touchstart', this.handleTouch);
      window.removeEventListener('touchstart', this.handleMove);
      window.removeEventListener('touchend', this.stopMovement);
    } catch(e) {
      console.log("no touch events")
    }
  }

  handleResize() {
    this.xPos = 50;
    this.yPos = 50;
    if((this.$parent as any).$root.deviceClass == 'desktop' &&
      (this.$parent as any).$root.screenClass == 'big') {
      this.clientX = "8%";
      this.clientY = 0;
    } else {
      this.clientX = "0";
      this.clientY = 0;
    }
  }

  handleTouch(e) {
    //console.log("touch");
    //initial x and y points
    this.initialX = e.touches[0].clientX;
    this.initialY = e.touches[0].clientY;

    //Start movement
    this.moveElement = true;
    this.started = true;

    if(!this.timer)
      this.openMask();

    e.preventDefault();
  }

  handleMove(e) {
    //console.log("move");
    //let container = document.get
    let w = window.innerWidth - 150,
      h = window.innerHeight - 150,
      newX = e.touches[0].clientX,
      newY = e.touches[0].clientY,
      moveX,
      moveY;
    //if movement == true then set top and left to new X andY while removing any offset
    //console.log(newX,newY);
    //console.log(w+'/'+this.initialX,h+'/'+this.initialY);
    // detect drag direction
    if(newY > this.initialY) {
      moveY = 'down';
    } else {
      moveY = 'up';
    }

    if(newX > this.initialX) {
      moveX = 'right';
    } else {
      moveX = 'left';
    }

    /*if((moveY == 'down' && this.initialY <= h) || 
      (moveY == 'up' && this.initialY >= h) ||
      (moveX == 'right' && this.initialX >= w) || 
      (moveX == 'left' && this.initialX <= w)) {
      this.moveElement = false;
    }*/
    // only working if mask on
    if (this.moveElement && this.mask) {
      if(!this.timer)
        this.openMask();

      let newX = e.touches[0].clientX,
        newY = e.touches[0].clientY;

      this.clientY = this.draggableElem.offsetTop - (this.initialY - newY);
      this.clientX = this.draggableElem.offsetLeft - (this.initialX - newX) + "px";
      
      this.initialX = newX;
      this.initialY = newY;

      this.$forceUpdate();
      //console.log(this.initialX,this.initialY);
    } 
    e.preventDefault();
  }

  stopMovement(e) {
    //console.log("stop");
    this.moveElement = false;
    e.preventDefault();
  }

  swipeHandler(mouseEvent) {
    // only working if mask on
    if((this.$parent as any).$root.deviceClass == 'mobile' && this.mask) {
      if(!this.timer)
        this.openMask();

      if(mouseEvent == 'top' && this.yPos < 100) {
        this.yPos = this.yPos + this.move;
      } else if(mouseEvent == 'bottom' && this.yPos > 0) {
        this.yPos = this.yPos - this.move;
      } else if(mouseEvent == 'left' && this.xPos < 100) {
        this.xPos = this.xPos + this.move;
      } else if(mouseEvent == 'right' && this.xPos > 0) {
        this.xPos = this.xPos - this.move;
      }
      this.$forceUpdate();
    }
  }

  moveMouse($event) {
    if(this.started && this.mask) {
      let el = $event.target.closest(".circleWrap");
      // only working if mask on
      if((this.$parent as any).$root.deviceClass == 'desktop' &&
        (this.$parent as any).$root.screenClass == 'big' && this.mask) {
        if(!this.timer)
          this.openMask();

          if($event.target.closest(".last")) {
              this.clientX = $event.clientX - window.innerWidth / 2 + 200 + "px";
              this.clientY = $event.clientY - window.innerHeight / 2;
          } else {
              this.clientX = ($event.clientX - window.innerWidth / 2) + 200 + "px";
              this.clientY = $event.clientY - window.innerHeight / 2;
          }

          
        this.$forceUpdate();
      }
    }
    
  }

  openMask() {
    //console.log("done");
    this.timer = true;
    setTimeout(() => {
        this.mask = false;
        this.$emit('init', true);
        this.draggableElem.removeEventListener('touchstart', this.handleTouch);
        this.draggableElem.removeEventListener('touchmove', this.handleMove);
        this.draggableElem.removeEventListener('touchend', this.stopMovement);
    }, 5000);
  }

  startExperience() {
    if(!this.started && (this.$parent as any).$root.deviceClass == 'desktop' &&
        (this.$parent as any).$root.screenClass == 'big') {
      this.started = true;
    }
  }
}
