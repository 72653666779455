import { createStore } from 'vuex'

export default createStore({
  state () {
    return {
      osClass: null,
      deviceClass: null,
      screenClass: null,
      browserClass: null,
      BASE_URL: 'https://inconditionnelles.com',
      profiles: null,
      modalOpen: false,
      wallText: true,
      wallAnim: false,
      isAudioCircle: null
    }
  },
  getters: {
    getOSClass(state) {
      return (state as any).osClass;
    },
    getDeviceClass(state: any) {
      return (state as any).deviceClass;
    },
    getScreenClass(state: any) {
      return (state as any).screenClass;
    },
    getBrowserClass(state: any) {
      return (state as any).browserClass;
    },
    getBASEURL(state: any) {
      return (state as any).BASE_URL;
    },
    getProfiles(state: any) {
      return (state as any).profiles;
    },
    getModalOpen(state: any) {
      return (state as any).modalOpen;
    },
    getWallText(state: any) {
      return (state as any).wallText;
    },
    getWallAnim(state: any) {
      return (state as any).wallAnim;
    },
    getAudioCircle(state: any) {
      return (state as any).isAudioCircle;
    }
    
  },
  mutations: {
    setOSClass(state, osClass: string) {
      (state as any).osClass = osClass;
    },
    setDeviceClass(state, deviceClass: string) {
      (state as any).deviceClass = deviceClass;
    },
    setScreenClass(state, screenClass: string) {
      (state as any).screenClass = screenClass;
    },
    setBrowserClass(state, browserClass: string) {
      (state as any).browserClass = browserClass;
    },
    setProfiles(state, profiles: any[]) {
      (state as any).profiles = profiles;
    },
    setModalOpen(state, modalOpen: boolean) {
      (state as any).modalOpen = modalOpen;
    },
    setWallText(state, wallText: boolean) {
      (state as any).wallText = wallText;
    },
    setWallAnim(state, wallAnim: boolean) {
      (state as any).wallAnim = wallAnim;
    },
    setAudioCircle(state, isAudioCircle: boolean) {
      (state as any).isAudioCircle = isAudioCircle;
    }
  },
  actions: {
  },
  modules: {
  }
})
