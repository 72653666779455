import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("canvas", {
    id: "canvas",
    width: _ctx.size,
    height: _ctx.size,
    ref: "canvas"
  }, null, 8, _hoisted_1))
}