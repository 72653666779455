import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

import LandingView from '../views/landing/landing.vue';
import HomeView from '../views/home/home.vue';
import CircleView from '../views/circle/circle.vue';
import ProfileView from '../views/profile/_id/_id.vue';

const CMS_URL = 'https://inconditionnelles.com/cms/?rest_route=/'
const BASE_URL = 'https://inconditionnelles.com/';
const title = 'Inconditionelles';
const description = "7 % de femmes dans la population carcérale canadienne. On ne les entend pas. 7 femmes vous offrent des fragments de leur histoire. Cette expérience est sonor. Autorisez-vous le temps de la rencontre.";


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'landing',
    component: LandingView
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/circle',
    name: 'circle',
    component: CircleView
  },
  {
    path: '/profile/:id',
    name: 'profile',
    component: ProfileView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
