<style lang="scss" src="./index.scss"></style>
<template src="./index.html"></template>

<script>
//import Vue from "vue";
import { osName, deviceType, browserName, osVersion } from "mobile-device-detect";
import { useCookies, globalCookiesConfig } from "vue3-cookies";

//import { bootstrap } from 'vue-gtag';
import { useMeta } from 'vue-meta'

globalCookiesConfig({
  expireTimes: "365d",
  path: "/",
  domain: "inconditionnelles.com"
});

export default {
  name: "App",
  components: {
    
  },
  created() {
    //initialization
    
  }, //end created
  mounted() {
    window.addEventListener("resize", this.resize);
    //initial functionality
    this.resize();
    this.getDeviceClass();
    this.osClass = osName.replace(/ /g, "_");
    this.scriptClass = "script-only";
    this.isJS = true;
    //console.log('gdprCookie',gdprCookie);
    

  },
  beforeUnmount() {
    //on destroy
    //event handlers
    window.removeEventListener("resize", this.resize);
  }, //end beforeDestroy
  data() {
    //needed for view, no complex computation needed
    return {
      osClass: "",
      osVersion: "",
      screenClass: "big", //default to mobile just in case
      deviceClass: "desktop",
      scriptClass: "noscript",
      title: "Inconditionelles",
      description: "7 % de femmes dans la population carcérale canadienne. On ne les entend pas. 7 femmes vous offrent des fragments de leur histoire. Cette expérience est sonor. Autorisez-vous le temps de la rencontre.",
      browserName: browserName,
      isJS: false,
      cookieAuth: true
    };
  }, //end data
  computed: {
    //functions w/ return obj
    
  }, //end computed
  methods: {
    //no return obj

    //window resize event handler
    resize() {
      this.getScreenClass();
    }, //end resize

    //determines screen size class
    getScreenClass() {
      //const deviceClass = this.getDeviceClass;

      if (this.deviceClass == "desktop") {
        //if desktop
        if (window.innerWidth < 750) {
          this.screenClass = "small";
        } else {
          this.screenClass = "big";
        }
      } else if (window.innerHeight > window.innerWidth) {
        //mobile/tablet
        this.screenClass = "portrait";
      } else {
        this.screenClass = "landscape";
      }
    }, //end getScreenClass

    //detects mobile/tablet/desktop
    getDeviceClass() {
      let deviceClass = deviceType ? deviceType : "desktop";

      if (
        this.deviceClass == "desktop" &&
        this.osClass == "Mac_OS" &&
        navigator.maxTouchPoints > 1
      ) {
        deviceClass = "tablet";
        
      }

      this.deviceClass = deviceClass;
      this.osVersion = osVersion ? osVersion : "";
    }, //end getDeviceClass
   // getImage(name) {
    //  return require(`~/assets/img/${name}`);
    //},
    // parse, replace image/shared url path and return the meta info
  }, //end methods
  watch: {
    osClass(newValue) {
      this.$store.commit("setOSClass", newValue);
    },
    screenClass(newValue) {
      this.$store.commit("setScreenClass", newValue);
    },
    deviceClass(newValue) {
      this.$store.commit("setDeviceClass", newValue);
    }
  },
  setup() {
    

    useMeta({
      title: 'Inconditionelles',
      htmlAttrs: { lang: 'en', amp: true }
    })

    const { cookies } = useCookies();
    return { cookies };
  }
}; //end app
</script>

